import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import "./component.scss";

const modalVariant = {
  initial: { opacity: 0 },
  isOpen: { opacity: 1 },
  exit: { opacity: 0 },
};
const containerVariant = {
  initial: { bottom: "-100%", transition: { type: "spring" } },
  isOpen: { bottom: "0%" },
  exit: { bottom: "-100%" },
};
const Modal = ({ style, children, isOpen }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={"initial"}
          animate={"isOpen"}
          exit={"exit"}
          className={"overlay"}
          variants={modalVariant}
        >
          <motion.div
            style={style}
            className="modal-container"
            variants={containerVariant}
          >
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
