import React, { useCallback, useContext } from "react";
import { useForm } from "react-hook-form";
import { useSwiper } from "swiper/react";
import Checkbox from "../../components/Checkbox";
import Input from "../../components/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { PhoneFormScheme } from "../../utils/validations";
import useAxios from "../../utils/useAxios";
import UserContext from "../../utils/store";
import { useSearchParams } from "react-router-dom";

function InputPhone() {
  const swiper = useSwiper();
  const { setUser } = useContext(UserContext);
  const { axiosInstance, loading } = useAxios();
  const params = useSearchParams()[0];
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(PhoneFormScheme),
    defaultValues: {
      phoneNumber: params?.get("phoneNumber") || "",
      remember: true,
    },
  });

  const onSubmit = useCallback(
    (data) => {
      let body = { ...data, phoneNumber: "+1" + data?.phoneNumber };
      axiosInstance.post("/generate_otp", body).then((res) => {
        if (res.status === 200) {
          setUser((d) => ({ ...d, ...body }));
          swiper.slideTo(1);
        }
      });
    },
    [axiosInstance, setUser, swiper]
  );

  return (
    <div className="px-4">
      <h5 className="text-center pb-2 pt-4">Let's do a quick verification</h5>
      <p className="text-center pb-4">
        To secure your future payments, we need to verify it’s you. Enter your
        mobile phone number to get started.
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder="Phone Number"
          className="mb-3"
          icon={"+1"}
          type="tel"
          error={errors?.phoneNumber?.message}
          register={() => register("phoneNumber")}
        />
        <Checkbox
          className="mb-5"
          label={"Remember me on this device."}
          register={() => register("remember")}
        />
        <button disabled={loading} class="btn btn-primary w-100" type="submit">
          {loading && (
            <span
              class="spinner-border spinner-border-sm me-2"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          Continue
        </button>
      </form>
    </div>
  );
}

export default InputPhone;
