import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSwiper, useSwiperSlide } from "swiper/react";
import BackIcon from "../../assets/BackIcon";
import InputOtp from "../../components/InputOtp";
import UserContext from "../../utils/store";
import useAxios from "../../utils/useAxios";

function InputOtpForm() {
  const swiper = useSwiper();
  const { user, setUser } = useContext(UserContext);
  const { axiosInstance, loading } = useAxios();
  const { isNext } = useSwiperSlide();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isNext) {
      setTimeout(() => {
        setError("");
        setOtp("");
      }, 400);
    }
  }, [isNext]);

  const onSubmit = useCallback(
    (e, data) => {
      e?.preventDefault();
      if (
        (data || otp).length !== 6 ||
        (!user?.liveMode && (data || otp) === "999999")
      ) {
        setError("Invalid Otp.");
        return;
      }
      setError("");
      setSubmitting(true);
      axiosInstance
        .post("/verify_otp", {
          phoneNumber: user?.phoneNumber,
          token: data || otp,
        })
        .then((res) => {
          if (res.status !== 200) {
            setError(res?.data?.message);
            setOtp("");
            setSubmitting(false);
          } else {
            setUser((d) => ({ ...d, token: res?.data?.token }));
            user?.remember && localStorage.setItem("token", res?.data?.token);
            if (!res?.data?.customer_details) {
              swiper.slideTo(2);
              return;
            }
            setTimeout(() => {
              navigate(
                "/?" + new URLSearchParams(window.location.search).toString()
              );
            }, 300);
          }
        })
        .catch((e) => setError(e.response.data.message));
    },
    [
      axiosInstance,
      navigate,
      otp,
      setUser,
      swiper,
      user?.liveMode,
      user?.phoneNumber,
      user?.remember,
    ]
  );

  return (
    <div className="d-flex flex-column align-items-center px-4">
      <BackIcon
        width={25}
        height={25}
        onClick={() => !loading && swiper.slidePrev()}
        role="button"
        className="align-self-start"
      />
      <h5 className="text-center pb-2 pt-4">Enter the 4-digit code</h5>
      <p className="text-center pb-4 w-75">
        A verification code has been sent to {user?.phoneNumber}{" "}
        <button
          type="button"
          class="btn btn-link p-0"
          disabled={loading}
          onClick={() => swiper.slidePrev()}
        >
          Change
        </button>
      </p>
      <form onSubmit={onSubmit}>
        <InputOtp
          value={otp}
          onChange={(e) => {
            setOtp(e);
            e?.length === 6 && onSubmit(undefined, e);
          }}
          error={error}
        />
        <button
          disabled={loading || submitting}
          class="btn btn-primary w-100 mt-5"
          type="submit"
        >
          {(loading || submitting) && (
            <span
              class="spinner-border spinner-border-sm me-2"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          Submit
        </button>
      </form>
      {!user?.liveMode && (
        <p className="w-50 bg-secondary text-white p-2 rounded mt-4 ">
          <small className="opacity-75">
            OTP is not sent in test mode. you can use any number other than
            "999999".
          </small>
        </p>
      )}
    </div>
  );
}

export default InputOtpForm;
