import { Elements, PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Account from "../../assets/Account";
import ApplePayIcon from "../../assets/ApplePayIcon";
import ChevronRight from "../../assets/ChevronRight";
import GPayIcon from "../../assets/GPayIcon";
import InfoIcon from "../../assets/InfoIcon";
import WalletIcon from "../../assets/WalletIcon";
import AppError from "../../components/AppError";
import AppLoader from "../../components/AppLoader";
import CardList, { getBrandIcon } from "../../components/CardList";
import Profile from "../../components/Profile";
import UserContext from "../../utils/store";
import useAxios from "../../utils/useAxios";
import AddCard, { stripePromise } from "../addCard/AddCard";
import "./payment.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRef } from "react";
import StoreIcon from "../../assets/StoreIcon";
import logo from "../../assets/cropedLogo.png";

function Payment() {
  const [showCards, setShowCards] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const [useBalance, setUseBalance] = useState(false);
  const [rememberMe, setRememberMe] = useState(!!localStorage.getItem("token"));
  const [showProfile, setShowProfile] = useState(false);
  const [addCard, setAddCard] = useState(false);
  const [error, setError] = useState("");
  const [paymentRequest, setPaymentRequest] = useState();
  const timer = useRef(false);

  const { axiosInstance, loading } = useAxios();
  const navigate = useNavigate();

  const submitOrder = useCallback(
    (cardId, paymentMethod, ev) => {
      const dif = user?.data?.order_amount - user?.data?.available_balance;
      axiosInstance
        .put("/pay", {
          payment_method_id: cardId,
          payment_method: paymentMethod,
          payment_type: useBalance
            ? dif > 0
              ? "partial_balance"
              : "balance"
            : "card",
          ...(user?.liveMode
            ? {}
            : {
                customer_firstname: user?.data?.firstname,
                customer_lastname: user?.data?.lastname,
                customer_email: user?.data?.customer_email,
                customer_phoneNumber: user?.data?.customer_phoneNumber,
              }),
        })
        .then((r) => {
          ev?.complete("success");
          if (r.status === 200) {
            // eslint-disable-next-line no-restricted-globals
            window.parent?.postMessage(
              {
                proceed: true,
                res: {
                  order_amount: r?.data?.order_amount,
                  signature: r?.data?.signature,
                  status: r?.data?.message,
                  metadata: r?.data?.metadata,
                },
              },
              "*"
            );
            navigate(
              "/success?" +
                new URLSearchParams(window.location.search).toString(),
              { state: { data: r?.data } }
            );
          }
        })
        .catch(() => {
          ev?.complete("fail");
        });
    },
    [
      axiosInstance,
      navigate,
      useBalance,
      user?.data?.available_balance,
      user?.data?.customer_email,
      user?.data?.customer_phoneNumber,
      user?.data?.firstname,
      user?.data?.lastname,
      user?.data?.order_amount,
      user?.liveMode,
    ]
  );

  const createPaymentRequest = useCallback(async () => {
    const stripe = await stripePromise;
    const pr = stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        amount: user?.data?.order_amount.toFixed(2) * 100,
        label: "PointPair",
      },
      requestPayerEmail: true,
      requestPayerName: true,
    });
    if (await pr.canMakePayment()) {
      setPaymentRequest(pr);
    }
    pr.on("paymentmethod", async (ev) => {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      submitOrder(ev.paymentMethod.id, user?.selectedCard?.id, ev);
    });
  }, [submitOrder, user?.data?.order_amount, user?.selectedCard?.id]);

  useEffect(() => {
    if (["googlePay", "applePay"].includes(user?.selectedCard?.id)) {
      createPaymentRequest();
    }
  }, [createPaymentRequest, user?.selectedCard?.id]);

  const getPaymentDetails = useCallback(async () => {
    if (user?.liveMode) {
      const res = await axiosInstance.put("/save_customer_id");
      if (res?.response?.status === 401) {
        localStorage.removeItem("token");
        setUser((d) => ({
          redirectUrl: d?.redirectUrl,
          liveMode: d?.liveMode,
          sessionToken: d?.sessionToken,
        }));
        return;
      }
    }
    axiosInstance
      .put("/get_details")
      .then((res) => {
        setUser((d) => ({
          ...d,
          data: { ...(d?.data || {}), ...res?.data?.data },
        }));
        user?.liveMode &&
          axiosInstance.get("/get_all_cards").then((cardRes) => {
            setUser((d) => ({
              ...d,
              cards: cardRes.data?.cards_data,
              selectedCard:
                cardRes.data?.cards_data?.find(
                  (i) => i?.id === res?.data?.data?.previously_used_method
                ) ||
                cardRes.data?.cards_data?.[0] ||
                null,
            }));
            !cardRes.data?.cards_data?.length && setAddCard(true);
          });
      })
      .catch((e) => setError(e?.response?.data?.message || e?.response?.data));
  }, [axiosInstance, setUser, user?.liveMode]);

  useEffect(() => {
    getPaymentDetails();
  }, [getPaymentDetails]);

  useEffect(() => {
    (async () => {
      const s = await stripePromise;
      const d = await s
        .paymentRequest({
          country: "US",
          currency: "usd",
          total: {
            label: "Total Amount",
            amount: 2000,
          },
        })
        .canMakePayment()
        .then((r) => {
          setUser((d) => ({ ...d, availablePayments: r }));
        });
    })();

    return () => {};
  }, [setUser]);

  const handleRememberMe = useCallback(
    (e) => {
      if (!e?.target?.checked) {
        localStorage.removeItem("token");
        setRememberMe(false);
      } else {
        localStorage.setItem("token", user?.token);
        setRememberMe(true);
      }
    },
    [user?.token]
  );

  const handleSubmit = useCallback(() => {
    if (user?.liveMode && !user?.selectedCard?.id) {
      setShowCards(true);
      return;
    }
    if (
      ["googlePay", "applePay"].includes(user?.selectedCard?.id) &&
      paymentRequest
    ) {
      paymentRequest.show();
    } else {
      submitOrder(user?.selectedCard?.id, undefined);
    }
  }, [paymentRequest, submitOrder, user?.liveMode, user?.selectedCard?.id]);

  useEffect(() => {
    if (showCards) document.body.style.overflow = "hidden";
    if (!showCards) document.body.style.overflow = "unset";
  }, [showCards]);
  useEffect(() => {
    if (addCard) document.body.style.overflow = "hidden";
    if (!addCard) document.body.style.overflow = "unset";
  }, [addCard]);

  if (error) {
    return <AppError message={error} />;
  }

  if (!user?.data) {
    return <AppLoader />;
  }

  return (
    <div className="payment">
      {(loading || !user?.data) && (
        <AppLoader showMessage={typeof loading === "string"} />
      )}
      <div className="d-flex flex-column" style={{ overflowY: "auto" }}>
        <div className="text-center ">
          <h1 className="h2 pt-4 mb-0 h3">
            <img src={logo} width={200} />
          </h1>
          {!!user?.merchantName && (
            <div className="d-flex text-primary align-items-center justify-content-center">
              <h1 className="me-2">
                <StoreIcon />
              </h1>
              <h5 className="mb-0 ">
                {"  "}
                {user?.merchantName}
              </h5>
            </div>
          )}
        </div>
        <div className="p-4 pt-0">
          <div className="box">
            <div
              className="flex-center"
              role={"button"}
              onClick={() => setShowProfile(true)}
            >
              <Account className="me-3" width={"2rem"} height={"2rem"} />
              <div className="flex-fill">
                <div className="email">{user?.data?.customer_email}</div>
                <div>
                  {user?.data?.customer_phoneNumber?.slice(0, 2)} (
                  {user?.data?.customer_phoneNumber?.slice(2, 5)}){" "}
                  {user?.data?.customer_phoneNumber?.slice(5, 8)}-
                  {user?.data?.customer_phoneNumber?.slice(8)}
                </div>
              </div>
              <ChevronRight />
            </div>
            <div className="footer flex-center small">
              <div className="flex-fill">
                Remember me on this device <InfoIcon className="ms-2" />
              </div>
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  checked={rememberMe}
                  onChange={handleRememberMe}
                  role="switch"
                  id="flexSwitchCheckDisabled"
                ></input>
              </div>
            </div>
          </div>
          <div className="box">
            <div className="flex-center">
              <WalletIcon className="me-3" size={"2rem"} />
              <div className="flex-fill">
                <div className="email">
                  Available Points{" "}
                  <strong>
                    ({((user?.data?.available_balance || 0) * 100)?.toFixed(0)})
                  </strong>
                </div>
              </div>
              <strong>${user?.data?.available_balance?.toFixed(2)}</strong>
            </div>
            <div className="footer flex-center small">
              <div className="flex-fill">
                Use points <InfoIcon className="ms-2" />
              </div>
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDisabled"
                  checked={useBalance}
                  onChange={(e) => {
                    if (user?.data?.available_balance > 0) {
                      setUseBalance(e?.target?.checked);
                    } else {
                      if (!timer.current) {
                        timer.current = true;
                        toast(
                          "You don't have enough points to apply for discount",
                          { type: "info" }
                        );
                        setTimeout(() => {
                          timer.current = false;
                        }, 3000);
                      }
                    }
                  }}
                ></input>
              </div>
            </div>
          </div>
          {useBalance &&
            user?.data?.order_amount - user?.data?.available_balance < 1 &&
            user?.data?.order_amount - user?.data?.available_balance > 0 && (
              <p className="small" style={{ fontSize: "0.7rem" }}>
                Minimum $1.00 can be charged from card.{" "}
                {(user?.data?.order_amount - 1)?.toFixed(2) * 100} points will
                be used and remaining points will be{" "}
                {(
                  user?.data?.available_balance -
                  user?.data?.order_amount +
                  1
                )?.toFixed(2) * 100}
                .
              </p>
            )}
          {useBalance &&
          user?.data?.available_balance >=
            user?.data?.order_amount ? null : user?.selectedCard ||
            !user?.liveMode ? (
            <div
              className="box"
              role={"button"}
              onClick={() => user?.liveMode && setShowCards(true)}
            >
              <div className="flex-center">
                {user?.selectedCard?.id === "googlePay" ? (
                  <GPayIcon size={2.5} />
                ) : user?.selectedCard?.id === "applePay" ? (
                  <ApplePayIcon size={2.5} />
                ) : (
                  getBrandIcon(
                    user?.liveMode ? user?.selectedCard?.card?.brand : "visa"
                  )
                )}
                <div className="ms-2 flex-fill">
                  <div className="email text-capitalize">
                    {user?.selectedCard?.id === "googlePay"
                      ? "Google Pay"
                      : user?.selectedCard?.id === "applePay"
                      ? "Apple Pay"
                      : user?.liveMode
                      ? user?.selectedCard?.card?.brand
                      : "Visa"}
                  </div>
                  {!["googlePay", "applePay"].includes(
                    user?.selectedCard?.id
                  ) && (
                    <div>•••• {user?.selectedCard?.card?.last4 || "4242"}</div>
                  )}
                </div>
                <ChevronRight />
              </div>
            </div>
          ) : (
            <div
              className="box"
              role={"button"}
              onClick={() => setShowCards(true)}
            >
              <div className="flex-center">
                <div className="ms-2 flex-fill">
                  <div className="email text-capitalize">
                    Choose payment method
                  </div>
                </div>
                <ChevronRight />
              </div>
            </div>
          )}
          {!useBalance && (
            <p className="cashback">
              You will get{" "}
              <span className="green">
                {user?.data?.cashback_percentage || 5}% in cash points
              </span>{" "}
              {/* if you pay total amount using card. */}
            </p>
          )}
        </div>
      </div>
      <div className="p-4 box">
        <div className="d-flex mb-1 justify-content-between">
          <div>Order Amount</div>
          <div>${user?.data?.order_amount?.toFixed(2)}</div>
        </div>
        {useBalance && (
          <div className="d-flex mb-1 justify-content-between">
            <div>Points</div>
            <div>
              - $
              {(user?.data?.available_balance > user?.data?.order_amount
                ? user?.data?.order_amount
                : user?.data?.order_amount - user?.data?.available_balance <
                    1 &&
                  user?.data?.order_amount - user?.data?.available_balance > 0
                ? user?.data?.order_amount - 1
                : user?.data?.available_balance
              )?.toFixed(2)}
            </div>
          </div>
        )}
        <div className="footer "></div>
        <div className="d-flex mb-3 h5 justify-content-between">
          <div>You Pay</div>
          <div>
            $
            {(useBalance
              ? user?.data?.available_balance > user?.data?.order_amount
                ? 0
                : user?.data?.order_amount - user?.data?.available_balance <
                    1 &&
                  user?.data?.order_amount - user?.data?.available_balance > 0
                ? 1
                : user?.data?.order_amount - user?.data?.available_balance
              : user?.data?.order_amount
            )?.toFixed(2)}
          </div>
        </div>
        {user?.selectedCard?.id === "applePay" && paymentRequest ? (
          <Elements stripe={stripePromise}>
            <PaymentRequestButtonElement options={{ paymentRequest }} />
          </Elements>
        ) : (
          <button
            class="btn btn-primary w-100"
            onClick={handleSubmit}
            type="submit"
          >
            Pay
          </button>
        )}
      </div>
      <CardList
        open={showCards}
        onClose={() => setShowCards(false)}
        onAddCard={() => {
          setShowCards(false);
          setAddCard(true);
        }}
      />
      <AddCard open={addCard} onClose={() => setAddCard(false)} />
      <Profile
        rememberMe={rememberMe}
        handleRememberMe={handleRememberMe}
        open={showProfile}
        onClose={() => setShowProfile(false)}
      />
    </div>
  );
}

export default Payment;
