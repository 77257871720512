import React from "react";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../assets/BackIcon";
import RegistrationForm from "../login/RegistrationForm";

function Profile() {
  const navigate = useNavigate();

  return (
    <>
      <BackIcon
        width={25}
        height={25}
        onClick={() =>
          navigate(
            "/?" + new URLSearchParams(window.location.search).toString()
          )
        }
        role="button"
        className="align-self-start m-2"
        style={{ position: "relative" }}
      />
      <RegistrationForm update />
    </>
  );
}

export default Profile;
