import React, { useCallback, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import PersonIcon from "../../assets/PersonIcon";
import Input from "../../components/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { RegistrationFormScheme } from "../../utils/validations";
import useAxios from "../../utils/useAxios";
import { useNavigate, useSearchParams } from "react-router-dom";
import UserContext from "../../utils/store";

function RegistrationForm({ update }) {
  const { axiosInstance, loading } = useAxios();
  const { user, setUser } = useContext(UserContext);
  const params = useSearchParams()[0];

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(RegistrationFormScheme),
    defaultValues: {
      firstname: params?.get("firstname") || "",
      lastname: params?.get("lastname") || "",
      email: params?.get("email") || "",
    },
  });

  useEffect(() => {
    if (update) {
      const { customer_email, firstname, lastname } = user?.data || {};
      reset({ email: customer_email, firstname, lastname });
    }
  }, [reset, update, user?.data]);
  useEffect(() => {
    if (user?.liveMode === false) {
      reset({
        email: params?.get("email") || "demo@gmail.com",
        firstname: params?.get("firstname") || "demo",
        lastname: params?.get("lastname") || "demo",
      });
    }
  }, [params, reset, user?.liveMode]);

  const onSubmit = useCallback(
    (data) => {
      axiosInstance
        .put("/update_customer_details", data, {
          headers: { Authorization: user?.token },
        })
        .then((res) => {
          if (res.status === 200) {
            if (!user?.liveMode) {
              localStorage.setItem(
                "testUser",
                JSON.stringify({
                  ...data,
                  phoneNumber:
                    user?.phoneNumber || user?.data?.customer_phoneNumber,
                })
              );
              setUser((d) => ({
                ...d,
                data: {
                  ...(d?.data || {}),
                  customer_phoneNumber:
                    user?.phoneNumber || user?.data?.customer_phoneNumber,
                  customer_email: data?.email,
                  ...data,
                },
              }));
            }
            navigate(
              "/?" + new URLSearchParams(window.location.search).toString()
            );
          }
        });
    },
    [
      axiosInstance,
      navigate,
      setUser,
      user?.data?.customer_phoneNumber,
      user?.liveMode,
      user?.phoneNumber,
      user?.token,
    ]
  );

  return (
    <div className="px-4">
      <h5 className="text-center pb-2 pt-4">
        {update ? "Update" : "Let's add few"} information
      </h5>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder="First Name"
          className="mb-3 mt-5"
          icon={<PersonIcon />}
          type="text"
          error={errors?.firstname?.message}
          register={() => register("firstname")}
        />
        <Input
          placeholder="Last Name"
          className="mb-3"
          icon={<PersonIcon />}
          type="text"
          error={errors?.lastname?.message}
          register={() => register("lastname")}
        />
        <Input
          placeholder="Email"
          className="mb-3"
          icon={"@"}
          type="text"
          error={errors?.email?.message}
          register={() => register("email")}
        />

        <button
          disabled={loading}
          class="btn btn-primary w-100 mt-3"
          type="submit"
        >
          {loading && (
            <span
              class="spinner-border spinner-border-sm me-2"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          Submit
        </button>
      </form>
    </div>
  );
}

export default RegistrationForm;
