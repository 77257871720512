import axios from "axios";
import constants from "./constants";

import React, { useContext, useEffect, useState } from "react";
import UserContext from "./store";
import { toast } from "react-toastify";
const axiosInstance = axios.create({ baseURL: constants.base_api });

function useAxios() {
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext) || {};

  useEffect(() => {
    const interceptor = axiosInstance.interceptors.request.use((config) => {
      setLoading(config.url === "/pay" ? "message" : true);
      config.headers.Authorization =
        config?.headers?.Authorization || user?.token;
      config.headers.sessionToken =
        config?.headers?.sessionToken || user?.sessionToken;
      return config;
    });
    return () => {
      axios.interceptors.request.eject(interceptor);
    };
  }, [user]);

  useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(
      (res) => {
        if (res?.data?.error) {
          toast(res?.data?.error?.message || "something went wrong", {
            type: "error",
          });
        }
        setLoading(false);
        return res;
      },
      (e) => {
        setLoading(false);
        console.log({ e });

        toast(
          e?.response?.data?.message ||
            e?.response?.data?.error?.message ||
            "something went wrong",
          {
            type: "error",
          }
        );
        return e;
      }
    );
    return () => {
      axios.interceptors.request.eject(interceptor);
    };
  }, []);

  return { axiosInstance, loading, setLoading };
}

export default useAxios;
