import { useContext } from "react";
import { Navigate } from "react-router-dom";
import UserContext from "../utils/store";

export const ProtectedRoute = ({ children }) => {
  const { user } = useContext(UserContext);
  if (!user?.token && !localStorage.getItem("token")) {
    // user is not authenticated
    return (
      <Navigate
        to={"/login?" + new URLSearchParams(window.location.search).toString()}
      />
    );
  }
  return children;
};
