import React from "react";

function AppLoader({ showMessage }) {
  return (
    <div
      className="app-container"
      style={{
        position: "absolute",
        top: 0,
        backgroundColor: showMessage ? "white" : "transparent",
      }}
    >
      <div className="loading">
        <div className="text-center">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only"></span>
          </div>
          {showMessage && (
            <p className="w-75 m-auto mt-2">
              <small>
                Please do not press the back button or reload the page during
                the payment process to avoid transaction issues.
              </small>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default AppLoader;
