import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import "./dasboard.scss";
import paymentGif from "../../assets/Successful purchase.gif";
import logo from "../../assets/cropedLogo.png";

function Dashboard() {
  const [count, setCount] = useState(15);

  const { state } = useLocation();

  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      setCount((d) => {
        if (d === 1) {
          clearInterval(interval);
          state?.data?.redirect_url &&
            window.location.replace(
              `${state?.data?.redirect_url}?status=success`
            );
          // eslint-disable-next-line no-restricted-globals
          window.parent?.postMessage(
            {
              proceed: true,
              res: {
                order_amount: state?.data?.order_amount,
                signature: state?.data?.signature,
                status: state?.data?.message,
                metadata: state?.data?.metadata,
                timer: true,
              },
            },
            "*"
          );
        }
        return d - 1;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [
    state?.data?.message,
    state?.data?.order_amount,
    state?.data?.redirect_url,
    state?.data?.signature,
  ]);

  if (!state?.data) {
    return (
      <Navigate
        to={"/?" + new URLSearchParams(window.location.search).toString()}
      />
    );
  }

  return (
    <div className="text-center dashboard d-flex flex-column  pb-4">
      <h1 className="h2 py-3 pt-5">
        <img src={logo} width={200} />
      </h1>
      <div className="d-flex flex-column flex-fill justify-content-center">
        <img src={paymentGif} alt="Payment" className="" />
        <div className="order-details ">
          <p className="h5">Payment Successful</p>
          {state?.data?.cashback_earned && (
            <p className="mt-3 mb-0 lead text-primary">
              Cash Points earned :{" "}
              <span style={{ fontWeight: "600" }}>
                {((state?.data?.cashback_earned || 0) * 100)?.toFixed(0)} points
              </span>{" "}
              (${state?.data?.cashback_earned?.toFixed(2)})
            </p>
          )}
          <small className="strong">
            Current points :{" "}
            {((state?.data?.current_balance || 0) * 100)?.toFixed(0)} ( $
            {state?.data?.current_balance?.toFixed(2)})
          </small>
        </div>
        <p className="mt-5">
          <small className="text-grey">you will be redirected in </small>
          <br />
          <span className="display-6">{count}</span>s
        </p>
        <button
          onClick={() => {
            // eslint-disable-next-line no-restricted-globals
            window.parent?.postMessage(
              {
                proceed: true,
                res: {
                  order_amount: state?.data?.order_amount,
                  signature: state?.data?.signature,
                  status: state?.data?.message,
                  metadata: state?.data?.metadata,
                  redirect: true,
                },
              },
              "*"
            );
            state?.data?.redirect_url &&
              window.location.replace(
                `${state?.data?.redirect_url}?status=success`
              );
          }}
          className="btn btn-link "
        >
          redirect now
        </button>
      </div>
    </div>
  );
}

export default Dashboard;
