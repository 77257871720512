import React, { useCallback, useContext, useEffect, useState } from "react";
import AmericanEx from "../assets/AmericanEx";
import ApplePayIcon from "../assets/ApplePayIcon";
import CloseIcon from "../assets/CloseIcon";
import DeleteIcon from "../assets/DeleteIcon";
import Discover from "../assets/Discover";
import GPayIcon from "../assets/GPayIcon";
import MasterIcon from "../assets/MasterIcon";
import VisaIcon from "../assets/VisaIcon";
import { stripePromise } from "../pages/addCard/AddCard";
import "../pages/payment/payment.scss";
import UserContext from "../utils/store";
import useAxios from "../utils/useAxios";
import Checkbox from "./Checkbox";
import Modal from "./Modal";

export const getBrandIcon = (brand) => {
  switch (brand) {
    case "visa":
      return <VisaIcon className="me-3" size={3.5} />;
    case "amex":
      return <AmericanEx className="me-3" size={3.5} />;
    case "discover":
      return <Discover className="me-3" size={3.5} />;
    case "mastercard":
      return <MasterIcon className="me-3" size={3.5} />;

    default:
      break;
  }
};

function CardList({ open, onClose, onAddCard }) {
  const { user, setUser } = useContext(UserContext);
  const [selectedCard, setSelectedCard] = useState(null);
  const { axiosInstance } = useAxios();

  useEffect(() => {
    if (open) {
      setSelectedCard(user?.selectedCard || null);
    }
  }, [open, user?.selectedCard]);

  const deleteCard = useCallback(
    (id) => {
      axiosInstance.put("/delete_card", { payment_method_id: id }).then(
        (r) =>
          r.status === 200 &&
          setUser((d) => ({
            ...d,
            cards: d?.cards?.filter((i) => i?.id !== id),
            ...(id === d?.selectedCard?.id && {
              selectedCard: d?.cards?.filter((i) => i?.id !== id)?.[0],
            }),
          }))
      );
    },
    [axiosInstance, setUser]
  );

  return (
    <Modal isOpen={open}>
      <div className="p-4 d-flex flex-column h-100">
        <div className="flex-fill">
          <div className="d-flex justify-content-end">
            <CloseIcon
              role={"button"}
              onClick={onClose}
              size={35}
              className=" mb-3"
            />
          </div>
          <h5 className="text-center mb-3">
            <strong>Change payment method</strong>
          </h5>
          <div
            className="payment"
            style={{
              height: "35vh",
              overflowY: "auto",
              justifyContent: "flex-start",
            }}
          >
            {user?.availablePayments?.googlePay && (
              <div className="box">
                <div className="flex-center">
                  <Checkbox
                    className="h4 me-2"
                    checked={selectedCard?.id === "googlePay"}
                    onChange={(t) => setSelectedCard({ id: "googlePay" })}
                    type="radio"
                  />
                  <GPayIcon size={2.5} />
                  <div className="flex-fill">
                    <div className="email text-capitalize ">Google Pay</div>
                  </div>
                </div>
              </div>
            )}
            {user?.availablePayments?.applePay && (
              <div className="box">
                <div className="flex-center">
                  <Checkbox
                    className="h4 me-2"
                    checked={selectedCard?.id === "applePay"}
                    onChange={(t) => setSelectedCard({ id: "applePay" })}
                    type="radio"
                  />
                  <ApplePayIcon size={2.5} />
                  <div className="flex-fill">
                    <div className="email text-capitalize ">Apple Pay</div>
                  </div>
                </div>
              </div>
            )}
            {user?.cards?.map((i) => (
              <div className="box" key={i?.id}>
                <div className="flex-center">
                  <Checkbox
                    className="h4 me-2"
                    checked={i?.id === selectedCard?.id}
                    onChange={(t) => setSelectedCard(i)}
                    type="radio"
                  />
                  {getBrandIcon(i?.card?.brand)}
                  <div className="flex-fill">
                    <div className="email text-capitalize ">
                      {i?.card?.brand}
                    </div>
                    <div>•••• {i?.card?.last4}</div>
                  </div>
                  <DeleteIcon onClick={() => deleteCard(i?.id)} role="button" />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <button
            class="btn btn-primary w-100 my-2"
            onClick={() => {
              setUser((d) => ({ ...d, selectedCard }));
              onClose();
            }}
            type="submit"
          >
            Continue
          </button>
          <button class="btn btn-outlined-primary w-100" onClick={onAddCard}>
            Add new
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default CardList;
