const Colors = {
  primary: "#37a802",
  backgroundColor: "#fff",
  sec_Primary: "#ff9800",
  secondary: "#f7f9f8",
  text: "#374256",
  textSecondary: "#AFAFBD",
  gray: "gray",
};

export default Colors;
