import React, { useCallback, useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.scss";
import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./pages/login/Login";
import NotFound from "./pages/notFound/NotFound";
import Payment from "./pages/payment/Payment";
import UserContext from "./utils/store";
import "react-toastify/dist/ReactToastify.css";
import { ProtectedRoute } from "./components/ProtectedRoute";
import AppLoader from "./components/AppLoader";
import Profile from "./pages/profile/Profile";
import useAxios from "./utils/useAxios";
import AppError from "./components/AppError";
import BackHandler from "./components/BackHandler";

const router = createBrowserRouter([
  {
    path: "/success",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
    errorElement: <NotFound />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Payment />
      </ProtectedRoute>
    ),
  },
  {
    path: "/profile",
    element: (
      <ProtectedRoute>
        <Profile />
      </ProtectedRoute>
    ),
  },
]);

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { axiosInstance } = useAxios();
  const [error, setError] = useState("");

  const getData = useCallback(async () => {
    let t = localStorage.getItem("token");
    setUser({ token: t });
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.get("session")) {
      setError("Invalid session");
      return;
    }
    const st = searchParams.get("session");
    setUser((d) => ({ ...(d || {}), sessionToken: st }));
    await axiosInstance
      .get("/", {
        headers: {
          sessionToken: st,
          Authorization: t,
        },
      })
      .then((r) => {
        if (r?.data?.error || r.status !== 200) {
          if (r?.data?.error?.name === "TokenExpiredError") {
            setError("Session Expired");
          } else {
            setError(r?.data?.error?.message || r?.response?.data?.message);
          }
        } else {
          setUser((d) => ({
            ...d,
            redirectUrl: r?.data?.redirect_url,
            liveMode: r?.data?.live_mode,
            merchantName: r?.data?.merchant_name,
            cashbackPercentage: r?.data?.cashback_percentage,
          }));
          if (!r?.data?.live_mode) {
            const testD = localStorage.getItem("testUser");
            if (testD) {
              const userD = JSON.parse(testD);
              setUser((d) => ({
                ...d,
                data: {
                  ...(d?.data || {}),
                  customer_phoneNumber: userD?.phoneNumber,
                  customer_email: userD?.email,
                  ...userD,
                },
              }));
            }
          }
        }
      })
      .catch((r) => setError(r?.response?.data?.message));
    setLoading(false);
  }, [axiosInstance]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (error) {
    return (
      <div className="app-container">
        <AppError message={error} />
      </div>
    );
  }

  if (loading) {
    return (
      <div className="app-container">
        <AppLoader />
      </div>
    );
  }

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="colored"
        limit={1}
      />
      <UserContext.Provider value={{ user, setUser, loading, setLoading }}>
        <div className="app-container" style={{ position: "relative" }}>
          {user?.liveMode === false && (
            <div className="text-white d-flex align-items-center test-mode">
              <div
                className="bg-primary mx-auto px-2 "
                style={{ borderRadius: "0 0 0.5rem 0.5rem" }}
              >
                <small>Test Mode</small>
              </div>
            </div>
          )}
          <BackHandler />
          <RouterProvider router={router}></RouterProvider>
        </div>
      </UserContext.Provider>
    </>
  );
}

export default App;
