import React, { useContext, useEffect } from "react";
import UserContext from "../utils/store";
import CloseIcon from "../assets/CloseIcon";

function BackHandler(props) {
  const { user } = useContext(UserContext);

  useEffect(() => {
    function handleListener(ev) {
      ev.preventDefault();
      // eslint-disable-next-line no-restricted-globals
      window.parent.postMessage({ close: true }, "*");
      window.location.replace(`${user?.redirectUrl}?status=cancelled`);
    }
    window.addEventListener("popstate", handleListener);
    return () => {
      window.removeEventListener("popstate", handleListener);
    };
  }, [user?.redirectUrl]);

  return (
    <div>
      {window.location.pathname !== "/success" && (
        <CloseIcon
          size={30}
          role="button"
          data-toggle="modal"
          data-target="#exampleModal"
          style={{ position: "absolute", top: 10, right: 10 }}
        />
      )}

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered " role="document">
          <div class="modal-content w-75 m-auto">
            <div class="modal-body">
              Are you sure you want to cancel payment.
            </div>
            <div class="container d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-secondary w-25 mb-2 p-0 "
                data-dismiss="modal"
              >
                No
              </button>
              <button
                onClick={() => {
                  window.parent.postMessage({ close: true }, "*");
                  user?.redirectUrl &&
                    window.location.replace(
                      `${user?.redirectUrl}?status=cancelled`
                    );
                }}
                type="submit"
                data-dismiss="modal"
                class="btn btn-primary p-0 mb-2 ms-2 w-25"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BackHandler;
