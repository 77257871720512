import React from "react";
import useAxios from "../utils/useAxios";

function Checkbox({ label, className = "", register, ...props }) {
  const { loading } = useAxios();

  return (
    <div class={"form-check " + className}>
      <input
        class="form-check-input"
        type="checkbox"
        {...props}
        {...(register && { ...register() })}
        id="flexCheckDefault"
        disabled={loading}
      />
      <label class="form-check-label" for="flexCheckDefault">
        {label}
      </label>
    </div>
  );
}

export default Checkbox;
