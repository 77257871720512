import React from "react";
import ErrorImage from "../assets/ErrorImage";
import logo from "../assets/cropedLogo.png";

function AppError({ message }) {
  return (
    <div className="text-center">
      <div className="pt-5">
        <img src={logo} width={"50%"} />
      </div>
      <ErrorImage width={"40%"} className="mt-5 mb-3" />
      <h3 className="">{message}</h3>
    </div>
  );
}

export default AppError;
