import React from "react";

function NotFound() {
  return (
    <div
      style={{ height: "100dvh" }}
      className=" d-flex flex-column align-items-center justify-content-center"
    >
      <h2>404 Not Found :(</h2>
    </div>
  );
}

export default NotFound;
