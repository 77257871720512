import React from "react";
import OtpInput from "react-otp-input";
import "./component.scss";

function InputOtp({ error, ...props }) {
  return (
    <>
      <OtpInput
        isInputNum
        numInputs={6}
        inputStyle={
          "form-control mx-2 my-2 otp-input p-0 " +
          (!!error ? "is-invalid" : "")
        }
        {...props}
        hasErrored={true}
      />
      {error && (
        <div>
          <small class="text-danger">{error}</small>
        </div>
      )}
    </>
  );
}

export default InputOtp;
