import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useCallback, useContext, useState } from "react";
import Input from "../../components/Input";
import UserContext from "../../utils/store";
import useAxios from "../../utils/useAxios";
import ApplePayIcon from "../../assets/ApplePayIcon";
import GPayIcon from "../../assets/GPayIcon";

function CardForm({ setLoading, clientSecret, onClose }) {
  const [errorMessage, setErrorMessage] = useState("");
  const { axiosInstance } = useAxios();
  const { user, setUser } = useContext(UserContext);

  const elements = useElements();
  const stripe = useStripe();

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      setLoading(true);
      const { error, setupIntent } = await stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: user?.data?.firstname + " " + user?.data?.lastname,
            },
          },
        }
      );

      if (error) {
        setErrorMessage(error.message);
      } else {
        onClose();
        axiosInstance.get("/get_all_cards").then((res) => {
          setUser((d) => ({
            ...d,
            cards: res.data?.cards_data,
            selectedCard: res.data?.cards_data?.[0] || null,
          }));
        });
      }
      setLoading(false);
    },
    [
      axiosInstance,
      clientSecret,
      elements,
      onClose,
      setLoading,
      setUser,
      stripe,
      user?.data?.firstname,
      user?.data?.lastname,
    ]
  );

  return (
    <form className="d-flex flex-column" onSubmit={handleSubmit}>
      <div className="d-flex flex-column flex-fill">
        <h4 className="text-center">Add a card</h4>
        <div className="p-4">
          {user?.availablePayments?.applePay && (
            <>
              <button
                onClick={() => {
                  setUser((d) => ({ ...d, selectedCard: { id: "applePay" } }));
                  onClose();
                }}
                className="btn btn-outline-secondary w-100 py-1 mb-3"
              >
                <div className="flex-center">
                  <ApplePayIcon size={2.5} />
                </div>
              </button>
              <p className="text-center text-muted">
                <small> or</small>
              </p>
            </>
          )}
          {user?.availablePayments?.googlePay && (
            <>
              <button
                onClick={() => {
                  setUser((d) => ({ ...d, selectedCard: { id: "googlePay" } }));
                  onClose();
                }}
                className="btn btn-outline-secondary w-100 py-1 mb-3"
              >
                <div className="flex-center">
                  <GPayIcon size={2.5} />
                </div>
              </button>
              <p className="text-center text-muted">
                <small> or</small>
              </p>
            </>
          )}
          <Input
            options={{
              showIcon: true,
              classes: {
                invalid: "is-invalid",
                base: "form-control",
                focus: "form-control-focus",
              },
            }}
            CustomComponent={CardNumberElement}
            inputStyle="py-2"
          />
          <div className="d-flex gap-3">
            <Input
              options={{
                showIcon: true,
                classes: {
                  invalid: "is-invalid",
                  base: "form-control",
                  focus: "form-control-focus",
                },
              }}
              CustomComponent={CardExpiryElement}
              inputStyle="py-2 "
              className="flex-fill"
            />
            <Input
              options={{
                showIcon: true,
                classes: {
                  invalid: "is-invalid",
                  base: "form-control",
                  focus: "form-control-focus",
                },
              }}
              CustomComponent={CardCvcElement}
              inputStyle="py-2"
              className="flex-fill"
            />
          </div>
        </div>
        <div className="text-center">
          <small class="text-danger ">{errorMessage}</small>
        </div>
      </div>
      <p class="text-muted text-center mt-5">
        <small>We will store your card details for future purchases.</small>
      </p>
      <button class="btn btn-primary w-auto mx-4 " type="submit">
        Add
      </button>
    </form>
  );
}

export default CardForm;
