import React, { useContext } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import InputPhone from "./InputPhoneForm";
import InputOtpForm from "./InputOtpForm";
import RegistrationForm from "./RegistrationForm";
import UserContext from "../../utils/store";
import StoreIcon from "../../assets/StoreIcon";
import logo from "../../assets/cropedLogo.png";

function Login() {
  const { user } = useContext(UserContext);

  return (
    <div className="py-4">
      <h2 className="text-center py-4">
        <img src={logo} width={200} />
      </h2>
      {!!user?.merchantName && (
        <div className="d-flex text-primary align-items-center justify-content-center">
          <h5 className="me-2 mb-0">
            <StoreIcon />
          </h5>
          <h5 className="mb-0 ">
            {"  "}
            {user?.merchantName}
          </h5>
        </div>
      )}
      <div className="text-center">
        <small className=" text-primary">
          Get {user?.cashbackPercentage}% cash points
        </small>
      </div>
      <Swiper
        slidesPerView={1}
        centeredSlides
        draggable={false}
        touchRatio={0}
        simulateTouch={false}
        loop={false}
      >
        <SwiperSlide className="swiper-no-swiping">
          <InputPhone />
        </SwiperSlide>
        <SwiperSlide className="swiper-no-swiping">
          <InputOtpForm />
        </SwiperSlide>
        <SwiperSlide className="swiper-no-swiping">
          <RegistrationForm />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default Login;
