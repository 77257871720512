import * as yup from "yup";

const stringRequired = yup.string().required();
const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?\s?\d{3}\s?\d{4}$/;

export const PhoneFormScheme = yup.object().shape({
  phoneNumber: yup.string().matches(phoneRegExp, "Phone number is not valid"),
});
export const OtpFormScheme = yup.object().shape({
  otp: stringRequired.min(6, "Enter complete OTP"),
});
export const RegistrationFormScheme = yup.object().shape({
  firstname: stringRequired.min(3, "Atleast 3 character required."),
  lastname: stringRequired,
  email: stringRequired.email(),
});
