import React from "react";
import useAxios from "../utils/useAxios";
import "./component.scss";

function Input({
  icon,
  className = "",
  error = "",
  register,
  CustomComponent,
  inputStyle = "",
  ...props
}) {
  const { loading } = useAxios();

  return (
    <div className={className || " my-3 "}>
      <div className="input-group ">
        {icon && (
          <span class="input-group-text" id="basic-addon1">
            {icon}
          </span>
        )}
        {CustomComponent ? (
          <CustomComponent
            className={
              "form-control " + (!!error ? "is-invalid" : "") + inputStyle
            }
            aria-describedby="basic-addon1"
            {...(register && { ...register() })}
            {...props}
            disabled={loading}
          />
        ) : (
          <input
            className={
              "form-control " + (!!error ? "is-invalid" : "") + inputStyle
            }
            aria-describedby="basic-addon1"
            {...(register && { ...register() })}
            {...props}
            disabled={loading}
          />
        )}
      </div>
      {error && (
        <div>
          <small class="text-danger">{error}</small>
        </div>
      )}
    </div>
  );
}

export default Input;
