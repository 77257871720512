import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useCallback, useEffect, useState } from "react";
import CloseIcon from "../../assets/CloseIcon";
import AppLoader from "../../components/AppLoader";
import Modal from "../../components/Modal";
import useAxios from "../../utils/useAxios";
import "./AddCard.scss";
import CardForm from "./CardForm";

export const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_KEY
);

function AddCard({ open, onClose }) {
  const { axiosInstance, loading, setLoading } = useAxios();
  const [secretKey, setSecretKey] = useState(undefined);

  const getSecretKey = useCallback(() => {
    axiosInstance.put("/add_card").then((res) => {
      setSecretKey(res?.data?.client_secret);
    });
  }, [axiosInstance]);

  useEffect(() => {
    open && getSecretKey();
  }, [getSecretKey, open]);

  return (
    <Modal style={{ height: "auto" }} isOpen={open}>
      <div className="py-4 d-flex flex-column">
        <div className="d-flex justify-content-end">
          <CloseIcon
            role={"button"}
            onClick={onClose}
            size={35}
            className=" mb-3 me-4"
          />
        </div>
        {/* {loading && <AppLoader />} */}
        {secretKey && (
          <Elements
            stripe={stripePromise}
            options={{ clientSecret: secretKey }}
          >
            <CardForm
              onClose={() => onClose()}
              setLoading={setLoading}
              clientSecret={secretKey}
            />
          </Elements>
        )}
      </div>
    </Modal>
  );
}

export default AddCard;
