import React, { useContext } from "react";
import Account from "../assets/Account";
import CloseIcon from "../assets/CloseIcon";
import UserContext from "../utils/store";
import Modal from "./Modal";
import "../pages/payment/payment.scss";
import InfoIcon from "../assets/InfoIcon";
import EditIcon from "../assets/EditIcon";
import { useNavigate } from "react-router-dom";

function Profile({ open, onClose, rememberMe, handleRememberMe }) {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <Modal isOpen={open} style={{ height: "auto", background: "#ebf6e5" }}>
      <div className="d-flex justify-content-end">
        <CloseIcon
          role={"button"}
          onClick={onClose}
          size={35}
          className="m-3"
        />
      </div>
      <div className="text-center">
        <Account width={"4rem"} height={"4rem"} />
        <p className="h4 mt-2 mb-4">
          {user?.data?.firstname} {user?.data?.lastname}
        </p>
      </div>
      <div className="p-4">
        <div className="box">
          <div className="px-3 small">
            <div className="d-flex">
              <div className="flex-fill">
                <div>Phone Number</div>
                <div style={{ fontWeight: "600" }}>
                  {user?.data?.customer_phoneNumber}
                </div>
                <div className="mt-2">Email</div>
                <div style={{ fontWeight: "600" }}>
                  {user?.data?.customer_email}
                </div>
              </div>
              <EditIcon
                role={"button"}
                onClick={() =>
                  navigate(
                    "/profile?" +
                      new URLSearchParams(window.location.search).toString()
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className="box">
          <div className=" flex-center small">
            <div className="flex-fill">
              Remember me on this device <InfoIcon className="ms-2" />
            </div>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                checked={rememberMe}
                onChange={handleRememberMe}
                role="switch"
                id="flexSwitchCheckDisabled"
              ></input>
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            className="btn btn-primary my-4"
            onClick={() => {
              localStorage.removeItem("token");
              setUser((d) => ({
                redirectUrl: d?.redirectUrl,
                liveMode: d?.liveMode,
                sessionToken: d?.sessionToken,
                merchantName: d?.merchantName,
                cashbackPercentage: d?.cashbackPercentage,
              }));
            }}
          >
            Sign out
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default Profile;
