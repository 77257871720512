import React from "react";

function ErrorImage(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <g id="freepik--background-complete--inject-2">
        <rect y="382.4" width="500" height="0.25" style={{ fill: "#ebebeb" }} />
        <rect
          x="416.78"
          y="398.49"
          width="33.12"
          height="0.25"
          style={{ fill: "#ebebeb" }}
        />
        <rect
          x="322.53"
          y="401.21"
          width="8.69"
          height="0.25"
          style={{ fill: "#ebebeb" }}
        />
        <rect
          x="396.59"
          y="389.21"
          width="19.19"
          height="0.25"
          style={{ fill: "#ebebeb" }}
        />
        <rect
          x="52.46"
          y="390.89"
          width="43.19"
          height="0.25"
          style={{ fill: "#ebebeb" }}
        />
        <rect
          x="104.56"
          y="390.89"
          width="6.33"
          height="0.25"
          style={{ fill: "#ebebeb" }}
        />
        <rect
          x="131.47"
          y="395.11"
          width="93.68"
          height="0.25"
          style={{ fill: "#ebebeb" }}
        />
        <path
          d="M237,337.8H43.91a5.71,5.71,0,0,1-5.7-5.71V60.66A5.71,5.71,0,0,1,43.91,55H237a5.71,5.71,0,0,1,5.71,5.71V332.09A5.71,5.71,0,0,1,237,337.8ZM43.91,55.2a5.46,5.46,0,0,0-5.45,5.46V332.09a5.46,5.46,0,0,0,5.45,5.46H237a5.47,5.47,0,0,0,5.46-5.46V60.66A5.47,5.47,0,0,0,237,55.2Z"
          style={{ fill: "#ebebeb" }}
        />
        <path
          d="M453.31,337.8H260.21a5.72,5.72,0,0,1-5.71-5.71V60.66A5.72,5.72,0,0,1,260.21,55h193.1A5.71,5.71,0,0,1,459,60.66V332.09A5.71,5.71,0,0,1,453.31,337.8ZM260.21,55.2a5.47,5.47,0,0,0-5.46,5.46V332.09a5.47,5.47,0,0,0,5.46,5.46h193.1a5.47,5.47,0,0,0,5.46-5.46V60.66a5.47,5.47,0,0,0-5.46-5.46Z"
          style={{ fill: "#ebebeb" }}
        />
        <rect
          x="101.29"
          y="76.16"
          width="291.79"
          height="136.52"
          style={{ fill: "#e6e6e6" }}
        />
        <rect
          x="103.34"
          y="76.16"
          width="294.13"
          height="136.52"
          style={{ fill: "#f0f0f0" }}
        />
        <rect
          x="101.29"
          y="212.68"
          width="291.79"
          height="6.96"
          style={{ fill: "#e6e6e6" }}
        />
        <rect
          x="110.66"
          y="212.68"
          width="294.13"
          height="6.96"
          style={{ fill: "#f0f0f0" }}
        />
        <rect
          x="188.3"
          y="3.51"
          width="124.21"
          height="281.82"
          transform="translate(394.83 -105.98) rotate(90)"
          style={{ fill: "#fafafa" }}
        />
        <polygon
          points="139.73 206.53 156.86 82.32 183.59 82.32 166.46 206.53 139.73 206.53"
          style={{ fill: "#fff" }}
        />
        <path
          d="M112.34,200.4a.44.44,0,0,1-.44-.44V86.32a.44.44,0,0,1,.44-.44.44.44,0,0,1,.44.44V200A.43.43,0,0,1,112.34,200.4Z"
          style={{ fill: "#f0f0f0" }}
        />
        <polygon
          points="172.2 206.53 189.33 82.32 199.75 82.32 182.62 206.53 172.2 206.53"
          style={{ fill: "#fff" }}
        />
        <rect
          x="328.82"
          y="144.03"
          width="124.21"
          height="0.78"
          transform="translate(535.35 -246.5) rotate(90)"
          style={{ fill: "#e6e6e6" }}
        />
        <polygon
          points="398.92 91.6 107.14 91.6 106.57 84.71 398.36 84.71 398.92 91.6"
          style={{ fill: "#ebebeb", opacity: 0.6000000000000001 }}
        />
        <polygon
          points="398.92 102.89 107.14 102.89 106.57 96 398.36 96 398.92 102.89"
          style={{ fill: "#ebebeb", opacity: 0.6000000000000001 }}
        />
        <polygon
          points="398.92 114.19 107.14 114.19 106.57 107.3 398.36 107.3 398.92 114.19"
          style={{ fill: "#ebebeb", opacity: 0.6000000000000001 }}
        />
        <polygon
          points="398.92 125.48 107.14 125.48 106.57 118.59 398.36 118.59 398.92 125.48"
          style={{ fill: "#ebebeb", opacity: 0.6000000000000001 }}
        />
        <polygon
          points="398.92 136.78 107.14 136.78 106.57 129.89 398.36 129.89 398.92 136.78"
          style={{ fill: "#ebebeb", opacity: 0.6000000000000001 }}
        />
        <polygon
          points="398.92 148.07 107.14 148.07 106.57 141.18 398.36 141.18 398.92 148.07"
          style={{ fill: "#ebebeb", opacity: 0.6000000000000001 }}
        />
        <rect
          x="394.47"
          y="247"
          width="44.53"
          height="135.4"
          style={{ fill: "#e6e6e6" }}
        />
        <polygon
          points="427.11 382.4 439 382.4 439 363.71 414.64 363.71 427.11 382.4"
          style={{ fill: "#fafafa" }}
        />
        <rect
          x="273.06"
          y="247"
          width="44.53"
          height="135.4"
          style={{ fill: "#e6e6e6" }}
        />
        <rect
          x="307.21"
          y="247"
          width="131.79"
          height="128.97"
          style={{ fill: "#fafafa" }}
        />
        <polygon
          points="319.1 382.4 307.21 382.4 307.21 363.71 331.57 363.71 319.1 382.4"
          style={{ fill: "#fafafa" }}
        />
        <rect
          x="316.43"
          y="295.06"
          width="113.34"
          height="32.27"
          style={{ fill: "#f0f0f0" }}
        />
        <rect
          x="316.43"
          y="334.23"
          width="113.34"
          height="32.27"
          style={{ fill: "#f0f0f0" }}
        />
        <path
          d="M334.24,292.26H412a0,0,0,0,1,0,0v1.57a4.67,4.67,0,0,1-4.67,4.67H338.92a4.67,4.67,0,0,1-4.67-4.67v-1.57A0,0,0,0,1,334.24,292.26Z"
          style={{ fill: "#fafafa" }}
        />
        <rect
          x="316.43"
          y="255.88"
          width="113.34"
          height="32.27"
          style={{ fill: "#f0f0f0" }}
        />
        <path
          d="M334.24,253.09H412a0,0,0,0,1,0,0v1.57a4.67,4.67,0,0,1-4.67,4.67H338.92a4.67,4.67,0,0,1-4.67-4.67v-1.57A0,0,0,0,1,334.24,253.09Z"
          style={{ fill: "#fafafa" }}
        />
        <path
          d="M334.24,331.43H412a0,0,0,0,1,0,0V333a4.67,4.67,0,0,1-4.67,4.67H338.92a4.67,4.67,0,0,1-4.67-4.67v-1.57A0,0,0,0,1,334.24,331.43Z"
          style={{ fill: "#fafafa" }}
        />
        <path
          d="M53.42,275.87c2.48-7.13,22,2.56,30,14.36,5.29,7.86,8.52,16.25,10,27.52-.18-12.86-.38-26.67-.58-39.71-1.61-3.25-5.28-10.75-7.91-16.69-8.8-19.82-29.72-27.2-37-39.71-9.47-16.4-9.41-41-1.35-49.86,7-7.72,30.2,44.43,32.82,50.26,9,20,11.6,34.91,13.28,49.32-.19-12.25-.37-23.49-.52-32.17-.49-28.3-1.11-37.4-1.39-40.2-1-1.37-2.11-3-2.78-3.82-5.61-7.23-18.07-15.83-24.29-26.34-9.09-15.34-7.62-33.7-1.25-40.54C69.17,121.14,81,138,87.21,154.78s3.58,32.76,5.34,46.67c.37,2.72.74,13.08,1.1,27.45,1.9-15.46,5.68-25.82,12.68-35.47,8.62-11.87,16.06-26.32,23.16-24.45s3.28,21-2.34,34.42c-5,11.95-29.68,23.88-33.32,33.49.43,19.48.81,44.15,1.09,67.12,1.94-11.87.6-12.86,8.58-31,4.74-10.8,24.17-24.11,26.49-16.69s-1.21,21.44-8.54,31.26c-5.92,7.93-19,7.92-22.09,12.83-.71,1.15-3,5.55-4.39,8.07.31,28.05.46,52.8.3,61.24l-1.28.09c.07-2.18-.09-16.45-.34-35.33-3.19-10-24.65-15.9-31.28-26.35C55.43,297.17,50.44,284.41,53.42,275.87Z"
          style={{ fill: "#e6e6e6" }}
        />
        <path
          d="M73.6,382.4h41.2a4.74,4.74,0,0,0,4.64-3.79l2.92-14.42H66L69,378.61A4.74,4.74,0,0,0,73.6,382.4Z"
          style={{ fill: "#f0f0f0" }}
        />
        <path
          d="M64.86,369.78h58.68a2.77,2.77,0,0,0,2.75-2.46l1.2-10.66a2.77,2.77,0,0,0-2.75-3.07H63.66a2.77,2.77,0,0,0-2.75,3.07l1.2,10.66A2.77,2.77,0,0,0,64.86,369.78Z"
          style={{ fill: "#f0f0f0" }}
        />
        <rect
          x="142.63"
          y="317.62"
          width="35.21"
          height="64.78"
          style={{ fill: "#e0e0e0" }}
        />
        <polygon
          points="267.58 382.4 149.69 382.4 137.3 294.5 255.19 294.5 267.58 382.4"
          style={{ fill: "#e6e6e6" }}
        />
        <polygon
          points="284.78 382.4 154.37 382.4 141.99 294.5 272.39 294.5 284.78 382.4"
          style={{ fill: "#f0f0f0" }}
        />
        <polygon
          points="167.13 368.66 156.7 308.24 259.63 308.24 270.06 368.66 167.13 368.66"
          style={{ fill: "#fff" }}
        />
        <polygon
          points="211.36 368.66 176.38 308.24 187.35 308.24 222.34 368.66 211.36 368.66"
          style={{ fill: "#fafafa" }}
        />
        <polygon
          points="226.99 368.66 192 308.24 210.72 308.24 245.7 368.66 226.99 368.66"
          style={{ fill: "#fafafa" }}
        />
      </g>
      <g id="freepik--Shadow--inject-2">
        <ellipse
          id="freepik--path--inject-2"
          cx="250"
          cy="416.24"
          rx="193.89"
          ry="11.32"
          style={{ fill: "#f5f5f5" }}
        />
      </g>
      <g id="freepik--Warning--inject-2">
        <path
          d="M77.27,221.53a.43.43,0,0,1-.43-.39l-2.22-26.7A.42.42,0,0,1,75,194a.43.43,0,0,1,.46.39l2.22,26.7a.43.43,0,0,1-.39.46Z"
          style={{ fill: "#37A802" }}
        />
        <path
          d="M78.59,237.39a.43.43,0,0,1-.43-.39l-.67-8.08a.42.42,0,0,1,.39-.46.42.42,0,0,1,.46.39l.67,8.08a.43.43,0,0,1-.39.46Z"
          style={{ fill: "#37A802" }}
        />
        <path
          d="M303.56,313.37H97.08a10.63,10.63,0,0,1-10.37-9.58L75.08,164a8.7,8.7,0,0,1,8.78-9.58H290.33A10.64,10.64,0,0,1,300.71,164l11.63,139.77A8.71,8.71,0,0,1,303.56,313.37Z"
          style={{ fill: "#fff" }}
        />
        <path
          d="M303.56,313.77H97.08a11,11,0,0,1-10.77-9.94L74.68,164.05A9.2,9.2,0,0,1,77.05,157,9.1,9.1,0,0,1,83.86,154H290.33a11,11,0,0,1,10.78,10l11.62,139.77a9.09,9.09,0,0,1-9.17,10ZM83.86,154.84A8.3,8.3,0,0,0,75.47,164L87.1,303.76a10.22,10.22,0,0,0,10,9.21H303.56a8.3,8.3,0,0,0,8.38-9.14L300.31,164.05a10.21,10.21,0,0,0-10-9.21Z"
          style={{ fill: "#37A802" }}
        />
        <path
          d="M290.33,154.44H83.86A8.7,8.7,0,0,0,75.08,164H300.71A10.64,10.64,0,0,0,290.33,154.44Z"
          style={{ fill: "#37A802" }}
        />
        <path
          d="M88.69,159.23a1.87,1.87,0,0,1-1.89,2.07,2.3,2.3,0,0,1-2.24-2.07,1.88,1.88,0,0,1,1.89-2.07A2.28,2.28,0,0,1,88.69,159.23Z"
          style={{ fill: "#fff" }}
        />
        <path
          d="M97.09,159.23a1.87,1.87,0,0,1-1.89,2.07A2.3,2.3,0,0,1,93,159.23a1.88,1.88,0,0,1,1.89-2.07A2.28,2.28,0,0,1,97.09,159.23Z"
          style={{ fill: "#fff" }}
        />
        <path
          d="M105.49,159.23a1.87,1.87,0,0,1-1.89,2.07,2.3,2.3,0,0,1-2.24-2.07,1.88,1.88,0,0,1,1.89-2.07A2.28,2.28,0,0,1,105.49,159.23Z"
          style={{ fill: "#fff" }}
        />
        <path
          d="M285.64,295.05H112a8.45,8.45,0,0,1-8.25-7.61L95.59,190a6.92,6.92,0,0,1,7-7.61H276.26a8.45,8.45,0,0,1,8.25,7.61l8.11,97.49A6.92,6.92,0,0,1,285.64,295.05Z"
          style={{ fill: "#f0f0f0" }}
        />
        <path
          d="M231.92,199.41l-53.8,64.78a15.39,15.39,0,0,0,8.58,24.88L274,307.94a15.39,15.39,0,0,0,17.55-20.76l-33.47-83.66A15.39,15.39,0,0,0,231.92,199.41Z"
          style={{ fill: "#263238", opacity: 0.1 }}
        />
        <path
          d="M112.16,208.49,93.42,259A9.72,9.72,0,0,0,103.48,272l54.73-5.37a9.72,9.72,0,0,0,6.65-15.74l-36-45.1A9.72,9.72,0,0,0,112.16,208.49Z"
          style={{ fill: "#263238", opacity: 0.1 }}
        />
        <path
          d="M250.48,183.1l-50.3,73a16.18,16.18,0,0,0,11.2,25.22l92.46,12.31a16.18,16.18,0,0,0,16.64-23.21l-42.16-85.34A16.18,16.18,0,0,0,250.48,183.1Z"
          style={{ fill: "#37A802" }}
        />
        <path
          d="M213.57,274.8a10.22,10.22,0,0,1-7.82-5.67,9,9,0,0,1,.47-9.2l50.43-73.22a8.48,8.48,0,0,1,8.29-3.52,10.2,10.2,0,0,1,7.82,5.67L315,274.41a9,9,0,0,1-.47,9.2,8.47,8.47,0,0,1-8.29,3.53Z"
          style={{ fill: "#fff" }}
        />
        <path
          d="M260.65,209.07l5.89.78a7.57,7.57,0,0,1,6.51,7.7l-.4,7.68a5.7,5.7,0,0,1-.09.83l-4.19,23.15a6.23,6.23,0,0,1-7.11,5h0a7.61,7.61,0,0,1-6.51-6.87l-1.69-23.92a5.77,5.77,0,0,1,0-.83l.39-7.69A6.21,6.21,0,0,1,260.65,209.07Zm-.47,51.56,1.6.21a6.78,6.78,0,0,1,5.83,6.89h0a5.57,5.57,0,0,1-6.45,5.25l-1.6-.22a6.77,6.77,0,0,1-5.83-6.88h0A5.56,5.56,0,0,1,260.18,260.63Z"
          style={{ fill: "#37A802" }}
        />
        <path
          d="M131.5,194.26l-13.81,52.37a9.72,9.72,0,0,0,11.27,12l53.57-10.45A9.72,9.72,0,0,0,187.72,232L148,190.05A9.73,9.73,0,0,0,131.5,194.26Z"
          style={{ fill: "#37A802" }}
        />
        <path
          d="M128.85,254.41a5.93,5.93,0,0,1-5.42-1.82,5.81,5.81,0,0,1-1.5-5.48l13.84-52.51a5,5,0,0,1,3.92-3.65,5.94,5.94,0,0,1,5.42,1.83l39.86,42a5.82,5.82,0,0,1,1.51,5.48,5,5,0,0,1-3.92,3.64Z"
          style={{ fill: "#fff" }}
        />
        <path
          d="M142.29,206.93l3.41-.67a4.52,4.52,0,0,1,5.08,3.27L152,214.1a4.64,4.64,0,0,1,.11.51L154.28,229a3.73,3.73,0,0,1-3,4.31h0a4.5,4.5,0,0,1-4.92-2.78l-5.54-13.68a4,4,0,0,1-.17-.49l-1.26-4.58A3.76,3.76,0,0,1,142.29,206.93Zm9.65,30.28.93-.18a4,4,0,0,1,4.55,2.92h0a3.36,3.36,0,0,1-2.56,4.3l-.93.18a4,4,0,0,1-4.55-2.91h0A3.37,3.37,0,0,1,151.94,237.21Z"
          style={{ fill: "#37A802" }}
        />
      </g>
      <g id="freepik--Character--inject-2">
        <path
          d="M388.36,206l-7.76,2.52,6.24,7.38a23.64,23.64,0,0,0,4.55-5.31,2.47,2.47,0,0,0-.88-3.35Z"
          style={{ fill: "#7f3e3b" }}
        />
        <polygon
          points="377.03 214.62 381.68 220.27 386.85 215.94 380.6 208.56 377.03 214.62"
          style={{ fill: "#7f3e3b" }}
        />
        <path
          d="M390.87,145.68c1.89,5.2,3.63,10.4,5.28,15.67s3.17,10.56,4.5,16.1l.47,2.13.22,1.11.11.56,0,.14,0,.21.06.42a17.53,17.53,0,0,1-.28,5.7,41.89,41.89,0,0,1-2.88,8.72,82.09,82.09,0,0,1-8.56,14.8l-5.19-2.87c1.84-5,3.66-10.11,5-15.06a42.22,42.22,0,0,0,1.38-7.09,8.38,8.38,0,0,0-.12-2.48l-.43-1.56-.56-1.85c-1.57-5-3.36-10.15-5.13-15.27s-3.54-10.29-5.24-15.42Z"
          style={{ fill: "#7f3e3b" }}
        />
        <path
          d="M347.68,114.74c.13.63-.09,1.21-.49,1.32s-.84-.33-1-1,.08-1.21.49-1.31S347.55,114.12,347.68,114.74Z"
          style={{ fill: "#263238" }}
        />
        <path
          d="M347.65,115.94a24.21,24.21,0,0,1-1.82,6.07,3.58,3.58,0,0,0,3.06-.18Z"
          style={{ fill: "#630f0f" }}
        />
        <path
          d="M346,111a.38.38,0,0,1-.31-.16.37.37,0,0,1,.09-.52,3.7,3.7,0,0,1,3.39-.26.37.37,0,0,1,.2.49.38.38,0,0,1-.49.21,2.9,2.9,0,0,0-2.67.17A.33.33,0,0,1,346,111Z"
          style={{ fill: "#263238" }}
        />
        <path
          d="M369.11,116.72c.7,7.26-.33,14.29,1.29,20.6,0,0-1.8,5.86-12.53,5.86-11.8,0-5.41-5.86-5.41-5.86,6.5-1.53,6.51-6.31,5.57-10.8Z"
          style={{ fill: "#7f3e3b" }}
        />
        <path
          d="M372.69,139.33c.92-1.85,1.53-4.48-.37-5.14-2.09-.73-13.26-1-17.31.4a4.28,4.28,0,0,0-3.07,5.13Z"
          style={{ fill: "#263238" }}
        />
        <path
          d="M396.8,402.69a9.77,9.77,0,0,0,2.12-.82.21.21,0,0,0,.12-.19.19.19,0,0,0-.14-.18c-.35-.12-3.45-1.15-4.27-.45a.66.66,0,0,0-.21.64,1.23,1.23,0,0,0,.66,1A2.63,2.63,0,0,0,396.8,402.69Zm1.48-1c-1.36.62-2.46.83-3,.55a.79.79,0,0,1-.44-.66.24.24,0,0,1,.07-.26C395.32,401,397,401.35,398.28,401.73Z"
          style={{ fill: "#37A802" }}
        />
        <path
          d="M398.88,401.89a.21.21,0,0,0,.15-.26c0-.1-.81-2.48-2-3a1.12,1.12,0,0,0-.91-.06h0a.66.66,0,0,0-.51.67c0,1,2.16,2.42,3.14,2.69Zm-2.5-3a.74.74,0,0,1,.5.06,4.93,4.93,0,0,1,1.61,2.4c-1-.45-2.42-1.59-2.44-2.17,0-.05,0-.17.24-.27h0Z"
          style={{ fill: "#37A802" }}
        />
        <path
          d="M342.18,408.8a10.44,10.44,0,0,0,2.25-.31.23.23,0,0,0,.16-.17.23.23,0,0,0-.1-.21c-.31-.19-3.09-1.9-4.05-1.41a.64.64,0,0,0-.35.58,1.26,1.26,0,0,0,.42,1.08A2.7,2.7,0,0,0,342.18,408.8Zm1.66-.6c-1.46.29-2.59.24-3.06-.16a.82.82,0,0,1-.28-.74c0-.16.07-.21.13-.24C341.12,406.81,342.71,407.54,343.84,408.2Z"
          style={{ fill: "#37A802" }}
        />
        <path
          d="M344.38,408.49a.27.27,0,0,0,.12,0,.22.22,0,0,0,.09-.19c0-.1-.22-2.59-1.22-3.41a1.08,1.08,0,0,0-.87-.26h0a.66.66,0,0,0-.65.53c-.18,1,1.54,2.85,2.44,3.34ZM342.64,405a.7.7,0,0,1,.47.17,4.89,4.89,0,0,1,1,2.71c-.89-.68-2-2.1-1.88-2.67,0,0,0-.18.3-.21h.09Z"
          style={{ fill: "#37A802" }}
        />
        <polygon
          points="353.67 408.18 345.77 408.18 345.69 389.9 353.59 389.9 353.67 408.18"
          style={{ fill: "#7f3e3b" }}
        />
        <polygon
          points="408.66 401.42 401.06 402.81 395.61 384.82 403.21 383.43 408.66 401.42"
          style={{ fill: "#7f3e3b" }}
        />
        <path
          d="M399.62,400.79l8.63-2a.66.66,0,0,1,.76.37l2.86,6.53a1.21,1.21,0,0,1-.85,1.65c-3,.66-4.52.82-8.32,1.72-2.33.55-7.09,1.94-10.32,2.7s-4.27-2.36-3-3c5.66-2.71,7.54-5,9.09-7.2A2,2,0,0,1,399.62,400.79Z"
          style={{ fill: "#263238" }}
        />
        <path
          d="M345.86,407.26h8.62a.66.66,0,0,1,.65.54l1.29,7a1.19,1.19,0,0,1-1.19,1.4c-3.11-.05-7.59-.23-11.5-.23-4.57,0-6.2.25-11.56.25-3.24,0-4-3.28-2.64-3.57,6.22-1.36,8.94-1.5,14.41-4.79A3.73,3.73,0,0,1,345.86,407.26Z"
          style={{ fill: "#263238" }}
        />
        <path
          d="M388,139.36c7.37,5.19,9.59,18.55,9.59,18.55l-16.7,8.46a79.67,79.67,0,0,1-6.1-16.57C373,141.9,382.76,135.68,388,139.36Z"
          style={{ fill: "#37A802" }}
        />
        <path
          d="M379.65,147.88l6.58,15.76-5.34,2.73a88.92,88.92,0,0,1-4.32-10.32Z"
          style={{ opacity: 0.2 }}
        />
        <path
          d="M338.89,174c.06,8.81.59,19.77,1.9,33.37H383c.51-6.2-2.08-35.43,4.21-64.7a3.87,3.87,0,0,0-3.19-4.64,96.6,96.6,0,0,0-10-1.07,155.68,155.68,0,0,0-17.95,0,70.18,70.18,0,0,0-7.19,1A9.58,9.58,0,0,0,341.5,145a83.7,83.7,0,0,0-2.31,16.45C339,165.05,338.86,169.22,338.89,174Z"
          style={{ fill: "#37A802" }}
        />
        <path
          d="M352.21,157.46c-2.79,2-8.13,3-13,3.41.08-1.29.18-2.51.29-3.66,1.31-.69,2.57-1.32,3.76-1.9Z"
          style={{ opacity: 0.2 }}
        />
        <g style={{ opacity: 0.2 }}>
          <polygon points="345.69 389.9 345.74 400.06 353.63 400.06 353.59 389.9 345.69 389.9" />
          <polygon points="403.21 383.43 395.61 384.83 398.43 394.1 406.03 392.7 403.21 383.43" />
        </g>
        <path
          d="M368,108.22c1.15,7.77,2.22,12.25-.78,17.15a10.18,10.18,0,0,1-18-1c-3.09-6.46-4.46-18.09,2.31-23A10.3,10.3,0,0,1,368,108.22Z"
          style={{ fill: "#7f3e3b" }}
        />
        <path
          d="M351.84,113.9c-2.93-1.29-2.65-5.9-1.76-7.84-3.39,1.16-8.29,1.83-9.17-1.62s2.11-4.55.28-7.5,4-6.16,6.72-2,9.44,2.3,13.78-1.67c3.16-2.89,7.78.84,5.95,4.61,0,0,9.5-.61,7.61,5.84s-4.56,6.67-3.95,11.26c.42,3.1.78,9.4-2.94,10.29C363.86,126.3,352.64,122.31,351.84,113.9Z"
          style={{ fill: "#263238" }}
        />
        <path
          d="M351.78,122.31c-.86.62-1.75,1.28-2.66,2l.05.11a10.51,10.51,0,0,0,4.09,4.51c.57-.55.89-2.4,1.95-2.37,3.17.09,9.3-1.24,10.32-4.82Z"
          style={{ opacity: 0.2 }}
        />
        <path
          d="M354.25,113.79a9.45,9.45,0,0,1-.76,5.07c-.9,1.83-2.32.66-2.87-1.59-.5-2-.63-5.41.67-6.41S354,111.51,354.25,113.79Z"
          style={{ fill: "#7f3e3b" }}
        />
        <path
          d="M355.77,207.4s6.63,60.32,10.67,82.92c4.43,24.75,27.37,102.43,27.37,102.43l14.14-3S392.16,317.07,390,292.57c-2.31-26.6-7-85.17-7-85.17Z"
          style={{ fill: "#37A802" }}
        />
        <path
          d="M355.77,207.4s6.63,60.32,10.67,82.92c4.43,24.75,27.37,102.43,27.37,102.43l14.14-3S392.16,317.07,390,292.57c-2.2-25.28-2.2-25.23-7-85.17Z"
          style={{ opacity: 0.3000000000000004 }}
        />
        <path
          d="M390.65,388c-.06,0,2.4,5.16,2.4,5.16L409,389.75l-1.13-4.82Z"
          style={{ fill: "#263238" }}
        />
        <path
          d="M360.6,228.9c10.28,2,7.69,40.65,5.68,60.52-2.44-14-5.77-41.07-8-60.24A3.49,3.49,0,0,1,360.6,228.9Z"
          style={{ opacity: 0.2 }}
        />
        <path
          d="M340.79,207.4s-3.36,59-3.43,83.09c-.08,25,5.5,107,5.5,107h13.38s3.6-80.51,5.14-105.1c1.68-26.81,7.17-85,7.17-85Z"
          style={{ fill: "#37A802" }}
        />
        <path
          d="M340.79,207.4s-3.36,59-3.43,83.09c-.08,25,5.5,107,5.5,107h13.38s3.6-80.51,5.14-105.1c1.68-26.81,7.17-85,7.17-85Z"
          style={{ opacity: 0.3000000000000004 }}
        />
        <path
          d="M340.5,392.18c-.06,0,.54,5.55.54,5.55H357.3l.63-5Z"
          style={{ fill: "#263238" }}
        />
        <path
          d="M357.52,151.74c-1.6.15-2.95.24-4.42.28s-2.87.07-4.3.06c-2.88,0-5.76-.18-8.66-.5a66,66,0,0,1-8.74-1.5,42.55,42.55,0,0,1-8.82-3.13l-.33-.17a5.21,5.21,0,0,1-2.38-7,5.45,5.45,0,0,1,.49-.81,28.16,28.16,0,0,1,4.05-4.33c1.35-1.2,2.7-2.27,4.06-3.29,2.72-2.05,5.5-3.86,8.29-5.62s5.64-3.39,8.51-5,5.76-3.06,8.76-4.45a3,3,0,0,1,3.07,5.06l0,0c-2.51,1.93-5.1,3.84-7.61,5.8s-5,3.89-7.49,5.87-4.86,4-7.13,6c-1.13,1-2.22,2-3.19,3.09a20.48,20.48,0,0,0-2.36,2.88l-2.21-8a36.65,36.65,0,0,0,6.72,1.79,70.8,70.8,0,0,0,7.41.86c2.53.16,5.09.25,7.66.25,1.29,0,2.58,0,3.86,0s2.63-.1,3.75-.16h.13a6,6,0,0,1,.92,11.93Z"
          style={{ fill: "#7f3e3b" }}
        />
        <path
          d="M363.12,114.72l-5,1.11s-6.69-.83-8.22,4.21l4,3.38,6.12,1a4.62,4.62,0,0,0,2.83-.46l1.56-.81a2.38,2.38,0,0,0,1.28-1.89l.37-4A2.38,2.38,0,0,0,363.12,114.72Z"
          style={{ fill: "#7f3e3b" }}
        />
        <path
          d="M353.36,157.58c-8.41,2.34-18-.58-18-.58l3.53-18.83s8.35-.74,13.57-.85C365.89,137,368.52,153.38,353.36,157.58Z"
          style={{ fill: "#37A802" }}
        />
      </g>
    </svg>
  );
}

export default ErrorImage;
